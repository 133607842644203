import {
  Flex,
  Switch,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
  InputRightAddon,
  Text,
  Stack,
  Collapse,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from "@chakra-ui/react";

import ContentCard from "./ContentCard";

export default function OtherSettingsCard({ state, dispatch }) {
  const formatDays = (value) => `${value} dni`;

  return (
    <ContentCard title="🚀 Inne ustawienia">
      <Stack
        px={4}
        py={1}
        p={[null, 6]}
        spacing={4}
        direction={["column", "column"]}
        align="start"
      >
      {/* 
        <Flex justify="flex-start" direction={["column", "row"]}>
          <FormControl maxW={300}>
            <FormLabel>📈 Ulga dla klasy średniej:</FormLabel>
            <Switch
              size="lg"
              isChecked={state.middleClassRelief}
              isDisabled={state.incomes[0].formOfTaxation !== "scale"}
              onChange={(e) =>
                dispatch({
                  type: "middleClassRelief",
                  payload: e.target.checked,
                })
              }
            />
          </FormControl>
        </Flex>
        */}
        <Flex justify="flex-start" direction={["column", "row"]}>
          <FormControl maxW={200}>
            <FormLabel>🌴 Odłóż na urlop:</FormLabel>
            <Switch
              size="lg"
              isChecked={state.vacation}
              onChange={(e) =>
                dispatch({ type: "vacation", payload: e.target.checked })
              }
            />
          </FormControl>
          <Collapse in={state.vacation} animateOpacity>
            <FormControl>
              <FormLabel>Liczba dni:</FormLabel>
              <InputGroup>
                <NumberInput
                  value={formatDays(state.vacationDays)}
                  onChange={(valueString) =>
                    dispatch({
                      type: "vacationDays",
                      payload: valueString,
                    })
                  }
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <InputRightAddon
                  children={`${(
                    state.vacationDays *
                    state.incomes.reduce((prev, current) => {
                      let daily = current.daily * current.rate;
                      return prev + daily;
                    }, 0)
                  ).toFixed(2)} PLN`}
                />
              </InputGroup>
            </FormControl>
            <Text as="em" fontSize="xs">
              Odłóż:{" "}
              {(
                (state.vacationDays *
                  state.incomes.reduce((prev, current) => {
                    let daily = current.daily * current.rate;
                    return prev + daily;
                  }, 0)) /
                12
              ).toFixed(2)}{" "}
              złotych miesięcznie
            </Text>
          </Collapse>
        </Flex>
        <Flex justify="flex-start" direction={["column", "row"]}>
          <FormControl maxW={200}>
            <FormLabel>👰 Rozliczenie ze współmałżonkiem:</FormLabel>
            <Switch
              size="lg"
              isChecked={state.married}
              isDisabled={state.incomes[0].formOfTaxation !== "scale"}
              onChange={(e) =>
                dispatch({ type: "married", payload: e.target.checked })
              }
            />
          </FormControl>

          <Collapse in={state.married} animateOpacity>
            <FormControl>
              <FormLabel>Dochód roczny:</FormLabel>
              <InputGroup>
                <NumberInput
                  value={state.spouseIncome}
                  onChange={(valueString) =>
                    dispatch({
                      type: "spouseIncome",
                      payload: valueString,
                    })
                  }
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </InputGroup>
            </FormControl>
            <Text as="em" fontSize="xs">
              Pomniejsz przychód o koszty (koszty uzyskania przychodu, składki
              ZUS, ulgę dla klasy średniej – jeśli się należy) oraz o
              przysługujące mu inne odliczenia.
            </Text>
          </Collapse>
        </Flex>
        <Flex justify="flex-start" direction={["column", "row"]}>
          <FormControl>
            <FormLabel>🚀 IPBOX:</FormLabel>
            <Switch
              size="lg"
              isChecked={state.ipbox}
              isDisabled={state.incomes[0].formOfTaxation.includes("lump")}
              onChange={(e) =>
                dispatch({ type: "ipbox", payload: e.target.checked })
              }
            />
          </FormControl>

          <Collapse in={state.ipbox} animateOpacity>
            <FormControl>
              <FormLabel>% dochodów z IPBOX:</FormLabel>
              <Slider
                w={300}
                aria-label="slider-ex-6"
                onChange={(val) =>
                  dispatch({ type: "ipboxIncomePart", payload: val })
                }
                min={0}
                max={100}
              >
                <SliderMark value={25} mt="1" ml="-2.5" fontSize="sm">
                  25%
                </SliderMark>
                <SliderMark value={50} mt="1" ml="-2.5" fontSize="sm">
                  50%
                </SliderMark>
                <SliderMark value={75} mt="1" ml="-2.5" fontSize="sm">
                  75%
                </SliderMark>
                <SliderMark
                  value={state.ipboxIncomePart}
                  textAlign="center"
                  bg="blue.500"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="12"
                >
                  {state.ipboxIncomePart}%
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
          </Collapse>
        </Flex>
      </Stack>
    </ContentCard>
  );
}
