import {
  Flex,
  CircularProgress,
  CircularProgressLabel,
  Text,
  Heading,
} from "@chakra-ui/react";

const pln = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "PLN",
});

// const StatsCard = ({ title, stat }) => (
//   <Stat flexBasis={"50%"}>
//     <Box>
//       <Badge fontSize={"xs"} colorScheme="red" mx={[0, 2]}>
//         {title}
//       </Badge>
//       <StatNumber fontSize={"md"}>{stat}</StatNumber>
//     </Box>
//   </Stat>
// );

const StatsCard2 = ({ title, stat, percentage = 0 }) => (
  <Flex mt={4}>
    <CircularProgress
      value={
        Math.round(percentage) !== Infinity && percentage
          ? Math.round(percentage)
          : 0
      }
      color="red.500"
      size={["45px", "80px"]}
      thickness="12px"
    >
      <CircularProgressLabel>
        {/* <TriangleDownIcon color="red.500" /> */}
        {Math.round(percentage) !== Infinity && percentage
          ? Math.round(percentage)
          : 0}
        %
      </CircularProgressLabel>
    </CircularProgress>
    <Flex
      ml={2}
      flexDirection={"column"}
      alignItems="flex-start"
      justifyContent={"space-evenly"}
    >
      <Text fontSize={["xs", "xl", "2xl"]} color="gray.500">
        {title}
      </Text>
      <Heading fontSize={["sm", "xl", "3xl"]}>{pln.format(stat)}</Heading>
    </Flex>
  </Flex>
);

export default function SummaryCard({ state }) {
  return (
    // <SimpleGrid columns={{ base: 2, md: 4 }} w="100%">
    <Flex w={"100%"} flexWrap={"wrap"} justifyContent={"space-between"}>
      <StatsCard2
        title={"Skł. Zdrowotna"}
        stat={state.toPay.healthTax}
        percentage={(state.toPay.healthTax * 100) / state.income}
      />
      <StatsCard2
        title={"Pod. Dochodowy"}
        stat={state.toPay.incomeTax}
        percentage={(state.toPay.incomeTax * 100) / state.income}
      />

      <StatsCard2
        title={"ZUS"}
        stat={state.toPay.socialTax.sum}
        percentage={(state.toPay.socialTax.sum * 100) / state.income}
      />
      <StatsCard2
        title={"VAT"}
        stat={state.toPay.vat}
        percentage={(state.toPay.vat * 100) / state.income}
      />

      {/*
            <StatsCard2 title={"Pod. Dochodowy"} stat={state.toPay.incomeTax} />
      <StatsCard2 title={"Pod. Dochodowy"} stat={state.toPay.healthTax} />
      <StatsCard
        title={"Skł. Zdrowotna"}
        stat={pln.format(state.toPay.healthTax)}
      />
      <StatsCard title={"ZUS"} stat={pln.format(state.toPay.socialTax.sum)} />
      <StatsCard title={"VAT"} stat={pln.format(state.toPay.vat)} />
      */}
    </Flex>
    // </SimpleGrid>
  );
}
