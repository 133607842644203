import { useState, useEffect } from "react";
import {
  Box,
  Badge,
  Flex,
  Button,
  Heading,
  useColorModeValue,
  Stack,
  ScaleFade,
  useColorMode,
  useMediaQuery,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import SummaryCard from "./SummaryCard";

const pln = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "PLN",
});

export default function Nav(state) {
  const { clearIncome } = state;
  const { colorMode, toggleColorMode } = useColorMode();
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile] = useMediaQuery("(max-width: 30em)");

  useEffect(() => {
    const listenToScroll = () => {
      let heightToHideFrom = 0;
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll > heightToHideFrom) {
        isVisible && // to limit setting state only the first time
          setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isVisible]);

  return (
    <Flex
      h={[52, "auto"]}
      bg={useColorModeValue("white", "gray.800")}
      position="fixed"
      w="100%"
      px={[4, 6]}
      py={2}
      zIndex={200}
      alignItems={"center"}
      justifyContent={"space-between"}
      boxShadow="lg"
      borderBottomRadius="lg"
      // borderBottom={1.5}
      // borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.700")}
      flexDirection={"column"}
    >
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        w="100%"
        h={[40, 16]}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        {!isMobile && (
          <ScaleFade initialScale={0.9} in={!isMobile}>
            <Box display="flex" flexDirection={["column", "row"]}>
              <Heading as="h2" fontSize={["xl", "xl", "2xl"]} mr={1}>
                Kalkulator
              </Heading>
              <Heading as="h2" fontSize={["xl", "xl", "2xl"]}>
                B2B
              </Heading>
            </Box>
          </ScaleFade>
        )}

        <Box
          display="flex"
          alignItems={!isMobile ? "center" : "flex-start"}
          flexDirection={{ base: "column", sm: "column", md: "row" }}
        >
          <Badge colorScheme="green" mx={[0, 2]}>
            Na czysto
          </Badge>
          <Heading fontSize={[`${!isMobile ? "2xl" : "4xl"}`, "2xl", "4xl"]}>
            {pln.format(clearIncome)}
          </Heading>
        </Box>

        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            <Button onClick={toggleColorMode}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button>
          </Stack>
        </Flex>
      </Flex>

      <Flex
        // alignItems={"center"}
        // borderBottom={1}
        // borderStyle={"solid"}
        // borderColor={useColorModeValue("gray.200", "gray.700")}
        bg={useColorModeValue("white", "gray.800")}
        justifyContent={"space-around"}
        w="100%"
        // h={[20, 16]}
      >
        <SummaryCard state={state} />
      </Flex>
    </Flex>
  );
}
