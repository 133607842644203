import { useState, useEffect } from "react";
import axios from "axios";

import {
  Button,
  SimpleGrid,
  useColorModeValue,
  Stack,
  Select,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

import ContentCard from "./ContentCard";

const IncomeInput = ({
  index,
  exchangeRates,
  state,
  dispatch,
  numberOfIncomes,
}) => {
  const formatDays = (value) => `${value} dni`;
  const formatHours = (value) => `${value} godz.`;
  const pln = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "PLN",
  });
  // const format = (value) => parseFloat(value);

  const deleteIncome = (index) => {
    dispatch({ type: "removeIncome", payload: { value: 0, index } });
  };

  return (
    <SimpleGrid columns={{ xl: 3, md: 1, sm: 1 }} spacing={6} py={2}>
      <FormControl>
        <FormLabel>📆 Miesięcznie:</FormLabel>
        <InputGroup>
          <NumberInput
            precision={2}
            step={0.01}
            minW={24}
            onChange={(valueString) =>
              dispatch({
                type: "monthly",
                payload: { value: valueString, index },
              })
            }
            value={state.monthly}
            min={0}
          >
            <NumberInputField />
          </NumberInput>
          <InputRightAddon
            padding={0}
            children={
              <NumberInput
                onChange={(valueString) =>
                  dispatch({
                    type: "day",
                    payload: { value: valueString, index },
                  })
                }
                value={formatDays(state.days)}
                min={0}
                maxW={24}
              >
                <NumberInputField border={0} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            }
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>📅 Dziennie:</FormLabel>

        <InputGroup>
          <NumberInput
            minW={24}
            precision={2}
            step={0.01}
            // maxW={24}
            onChange={(valueString) =>
              dispatch({
                type: "daily",
                payload: { value: valueString, index },
              })
            }
            value={state.daily}
            min={0}
          >
            <NumberInputField borderRight={0} />
          </NumberInput>
          <InputRightAddon
            padding={0}
            children={
              <NumberInput
                size="sm"
                onChange={(valueString) =>
                  dispatch({
                    type: "hours",
                    payload: { value: valueString, index },
                  })
                }
                value={formatHours(state.hours)}
                min={0}
                maxW={24}
              >
                <NumberInputField border={0} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            }
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>⏱ Na godzinę:</FormLabel>
        <InputGroup>
          <NumberInput
            precision={2}
            step={0.01}
            onChange={(valueString) =>
              dispatch({
                type: "hourly",
                payload: { value: valueString, index },
              })
            }
            value={state.hourly}
            min={0}
          >
            <NumberInputField />
          </NumberInput>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>💱 Waluta:</FormLabel>
        <Select
          value={state.currency}
          onChange={(e) =>
            dispatch({
              type: "currency",
              payload: {
                value: {
                  currency: e.target.value,
                  rate: exchangeRates[e.target.value],
                },
                index,
              },
            })
          }
        >
          <option value="PLN">PLN</option>
          {Object.entries(exchangeRates).map(([currency, rate]) => (
            <option key={currency} value={currency}>
              {currency} ({rate})
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>🧾 Stawka VAT:</FormLabel>
        <Select
          placeholder="Wybierz stawkę VAT"
          value={state.vat}
          onChange={(e) =>
            dispatch({
              type: "vat",
              payload: { value: e.target.value, index },
            })
          }
        >
          <option value="23">23%</option>
          <option value="8">8%</option>
          <option value="5">5%</option>
          <option value="0">0%</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>🏢 Forma opodatkowania:</FormLabel>
        <Select
          value={state.formOfTaxation}
          onChange={(e) =>
            dispatch({
              type: "formOfTaxation",
              payload: { value: e.target.value, index },
            })
          }
          // placeholder="Wybierz formę opodatkowania"
        >
          <option value="scale">Skala podatkowa (12% / 32%)</option>
          <option value="flat">Podatek liniowy (19%)</option>
          <option value="lump_17">Ryczałt (17%)</option>
          <option value="lump_15">Ryczałt (15%)</option>
          <option value="lump_14">Ryczałt (14%)</option>
          <option value="lump_12.5">Ryczałt (12.5%)</option>
          <option value="lump_12">Ryczałt (12%)</option>
          <option value="lump_10">Ryczałt (10%)</option>
          <option value="lump_8.5">Ryczałt (8.5%)</option>
          <option value="lump_5.5">Ryczałt (5.5%)</option>
          <option value="lump_3">Ryczałt (3%)</option>
          <option value="lump_2">Ryczałt (2%)</option>
        </Select>
      </FormControl>
      {numberOfIncomes > 1 && (
        <Button
          size={"sm"}
          leftIcon={<DeleteIcon />}
          colorScheme="red"
          variant="solid"
          onClick={() => deleteIncome(index)}
        >
          Usuń źródło dochodu
        </Button>
      )}
    </SimpleGrid>
  );
};

export default function IncomeCard({ state, dispatch }) {
  const [exchangeRates, setExchangeRates] = useState({
    USD: 3.9693,
    EUR: 4.5274,
    GBP: 5.3792,
    PLN: 1,
  });

  useEffect(() => {
    const currencies = ["USD", "EUR", "GBP"];
    let rates = {};
    async function fetchRate(currencies) {
      for (const currency of currencies) {
        const { data } = await axios.get(
          `https://api.nbp.pl/api/exchangerates/rates/a/${currency}?format=json`
        );
        rates[currency] = data.rates[0].mid;
      }

      setExchangeRates({ ...rates });
    }

    fetchRate(currencies);
  }, []);

  const addNewIncome = () => {
    dispatch({
      type: "newIncome",
      payload: { value: null, index: null },
    });
  };

  return (
    <ContentCard title="💰 Informacje o przychodach">
      <Stack
        px={4}
        p={[null, 0]}
        bg={useColorModeValue("white", "gray.700")}
        spacing={[0, 4, 6]}
      >
        {state.map((income, index) => (
          <IncomeInput
            key={index}
            index={index}
            exchangeRates={exchangeRates}
            state={income}
            dispatch={dispatch}
            numberOfIncomes={state.length}
          />
        ))}
      </Stack>
      <Button
        size={"sm"}
        leftIcon={<AddIcon />}
        colorScheme="blue"
        variant="solid"
        onClick={() => addNewIncome()}
      >
        Dodaj źródło dochodu
      </Button>
    </ContentCard>
  );
}
