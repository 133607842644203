import { useColorModeValue, Stack, Text, Divider } from "@chakra-ui/react";

export default function ContentCard({ title, children }) {
  return (
    <Stack
      p={4}
      m={[0, 0]}
      mx={[0, 2]}
      boxShadow="lg"
      borderRadius="lg"
      bg={useColorModeValue("white", "gray.700")}
    >
      <Stack direction="column" alignItems="center">
        <Text fontWeight="semibold">{title}</Text>
        <Divider />
      </Stack>

      {children}
    </Stack>
  );
}
