import {
  Box,
  useColorModeValue,
  Stack,
  Switch,
  FormControl,
  FormLabel,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";

import ContentCard from "./ContentCard";

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "blue.500",
          color: "white",
          borderColor: "blue.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={[2, 5]}
        py={[2, 3]}
        my={1}
        mx={1}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function SocialCard({ state, dispatch }) {
  const options = [
    "Brak ulgi",
    "Składka preferencyjna",
    "Ulga na start",
    "Umowa o pracę",
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: state.socialPolicy,
    colorScheme: "blue",
    onChange: (nextValue) => {
      dispatch({ type: "socialPolicy", payload: nextValue });
    },
  });
  const group = getRootProps();
  return (
    <ContentCard title="👴 Informacje o ubezpieczeniu społecznym (ZUS)">
      <Stack
        px={4}
        py={5}
        p={[null, 6]}
        bg={useColorModeValue("white", "gray.700")}
        spacing={1}
      >
        <FormControl>
          <FormLabel>👴 Składka na ubezpieczenie społeczne:</FormLabel>
          <Box
            {...group}
            display="flex"
            flexWrap="wrap"
            alignContent={"stretch"}
          >
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard key={value} {...radio}>
                  {value}
                </RadioCard>
              );
            })}
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>🤮 Dobrowolne ubezpieczenie chorobowe:</FormLabel>
          <Switch
            size="lg"
            isChecked={state.sickness}
            isDisabled={
              state.socialPolicy === "Ulga na start" ||
              state.socialPolicy === "Umowa o pracę"
            }
            onChange={(e) =>
              dispatch({ type: "sickness", payload: e.target.checked })
            }
          />
        </FormControl>
      </Stack>
    </ContentCard>
  );
}
